<template>
  <b-modal
    id="import-fact-ratings-modal"
    ref="import-fact-ratings-modal"
    :title="$t('broadcastSideBar.importFactRating')"
    size="xl"
    :ok-disabled="$v.$invalid || processing"
    :ok-title="processing ? $t('table.importing') + '...' : $t('table.import')"
    :cancel-title="$t('table.cancel')"
    dialog-class="modal-w-1600px"
    @ok.prevent="importFactRatings"
    @show="loadData()"
    @hidden="clearFields"
  >
    <form @submit.stop.prevent="checkIfValidThenEnter">
      <input type="submit" value="Submit" class="hidden-submit" />
      <b-form-checkbox id="one-day-mode-radio" v-model="one_day_only" name="one-day-mode-radio" switch @input="toggleTableRows()">
        {{ $t('table.oneDayImportMode') }}
      </b-form-checkbox>

      <table id="import-files-table" class="table table-sm mt-2 ws-nowrap">
        <thead>
          <tr>
            <th class="pl-0">{{ $t('channelModal.channel') }}</th>
            <th>
              <div class="d-flex gap-1 datepickers-wrapper-header">
                <div>{{ $t('channelModal.from') }}</div>
                <span>-</span>
                <div>{{ $t('channelModal.to') }}</div>
              </div>
            </th>
            <th style="width: 30%">{{ $t('table.selectFile') }}</th>
            <th>
              <abbr :title="$t('table.importDeltaExplanation')">
                {{ $t('table.timeDelta') }}
              </abbr>
            </th>
            <th>{{ $t('channelModal.measurements') }}</th>
            <th>
              <abbr :title="$t('table.importWithCleanImport')">{{ $t('table.cleanImport') }}</abbr>
            </th>
            <th>{{ $t('table.continueMatching') }}</th>
            <th style="min-width: 120px">{{ $t('table.status') }}</th>
            <th style="min-width: 22px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in rows" :key="index">
            <td class="pl-0">
              <MultiSelect
                v-model="row.channel"
                class="multiselect-sm w-270px"
                :options="channels"
                :placeholder="$t('table.selectChannel')"
                label="name"
                track-by="id"
                :disabled="processing || row.status.result === 'success'"
                @input="checkImportsInDay(index)"
              ></MultiSelect>
            </td>
            <td>
              <div class="d-flex gap-1 datepickers-wrapper">
                <datepicker-wrapper
                  v-model="row.dayFrom"
                  class="w-150px"
                  required
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  :disabled="processing || row.status.result === 'success'"
                  @input="selectedDayFrom(index)"
                />
                <datepicker-wrapper
                  v-model="row.dayTo"
                  class="w-150px"
                  required
                  :min="row.dayFrom"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  :disabled="processing || row.status.result === 'success'"
                  @input="checkImportsInDay(index)"
                />
              </div>
            </td>
            <td>
              <b-form-file
                v-model="row.file"
                :placeholder="$t('table.selectFileOrDrop')"
                :drop-placeholder="$t('table.dropPlaceholder')"
                :browse-text="$t('table.browse')"
                accept=".xlsx, .xls"
                size="sm"
                required
                :disabled="processing || row.status.result === 'success'"
              ></b-form-file>
            </td>
            <td>
              <b-form-input
                v-model.number="row.time_delta"
                type="number"
                step="1"
                min="1"
                size="sm"
                :placeholder="$t('reports.minutes')"
                style="width: 85px"
                :disabled="processing || row.status.result === 'success'"
              ></b-form-input>
            </td>
            <td>
              <MultiSelect
                v-model="row.measurement"
                class="multiselect-sm w-150px"
                :options="measurementsList || []"
                :placeholder="$t('channelModal.measurements')"
                label="name"
                track-by="id"
                :disabled="processing || row.status.result === 'success'"
              ></MultiSelect>
            </td>
            <td>
              <b-form-checkbox
                :id="`clear-radio-${index}`"
                v-model="row.clear_day"
                size="sm"
                :name="`clear-radio-${index}`"
                :disabled="processing || row.status.result === 'success' || !row.isExistImports"
              >
                &nbsp;
              </b-form-checkbox>
            </td>
            <td>
              <div v-if="row.isExistImports">
                <b-button
                  v-for="(ex, exInd) in row.existImports"
                  :key="exInd"
                  class="p-0"
                  variant="link d-block"
                  size="sm"
                  :title="$t('table.continueMatching')"
                  @click="openMatchingFactPage(row, ex)"
                >
                  {{ ex | convertDate }}
                </b-button>
              </div>
            </td>
            <td>
              <template v-if="row.status.result === 'processing'">
                <b-spinner small :label="$t('table.processing')" class="mr-1"></b-spinner>{{ $t('table.processing') }}
              </template>
              <template v-else-if="row.status.result === 'error'">
                <b-icon icon="exclamation-circle" class="text-danger mr-1"></b-icon>{{ $t('table.error') }}
              </template>
              <b-button v-else-if="row.status.result === 'success'" class="p-0" variant="link" @click="openMatchingFactPage(row, undefined, true)">
                <b-icon icon="check" variant="success" class="mr-1" font-scale="1.2"></b-icon>
                {{ $t('table.openVerb') }}
              </b-button>
              <template v-else-if="processing && row.status.result === ''">
                {{ $t('table.inQueue') }}
              </template>
            </td>
            <td>
              <button
                v-if="row.status.result !== 'success' && rows.length > 1"
                class="btn-transparent trash-hover"
                type="button"
                :disabled="processing"
                @click="deleteRow(index)"
              >
                <b-icon icon="trash" :title="$t('table.delete')"></b-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <b-button v-if="!one_day_only" size="sm" :disabled="processing" @click="addRow">
        {{ $t('table.addRow') }}
      </b-button>
      <b-form-checkbox
        v-else-if="
          !(this.$route.name === 'matchingFactImport' && rows[0] && rows[0].channel && channelId === rows[0].channel.id && rows[0].dayFrom === selectedDay)
        "
        id="auto-open-radio"
        v-model="open_matching_page"
        name="auto-open-radio"
      >
        {{ $t('table.openMatchingFactAutomatically') }}
      </b-form-checkbox>
    </form>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minValue } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import MultiSelect from '@/components/MultiSelect';
import { BFormFile, BIconCheck, BIconExclamationCircle, BSpinner } from 'bootstrap-vue';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import getDatesInRange from '@/utils/getDatesInRange';
import convertDate from '@/filters/convertDate';

export default {
  name: 'ImportFactRating',
  // eslint-disable-next-line vue/no-unused-components
  components: { MultiSelect, BFormFile, BSpinner, BIconCheck, BIconExclamationCircle, DatepickerWrapper },
  filters: { convertDate },
  props: {
    channelId: {
      type: Number,
      default: undefined,
    },
    selectedDay: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      one_day_only: true,
      open_matching_page: true,
      processing: false,
      rows: [],
    };
  },
  validations: {
    rows: {
      $each: {
        channel: { required },
        measurement: { required },
        file: { required },
        time_delta: { minValue: minValue(1) },
        dayFrom: { required },
        dayTo: { required },
      },
    },
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      measurementsList: 'getMeasurementsList',
      channels: 'getChannel',
    }),
  },
  watch: {},
  methods: {
    clearFields() {
      this.rows = [];
      this.one_day_only = true;
      this.open_matching_page = true;
    },

    async loadData() {
      await Promise.all([
        !this.channels || this.channels.length < 1 ? this.$store.dispatch('GET_CHANNEL', { per_page: 1000 }) : undefined,
        this.measurementsList.length < 1 ? this.$store.dispatch('GET_MEASUREMENTS', { per_page: 1000 }) : undefined,
      ]);
      this.addRow();
    },

    toggleTableRows() {
      if (this.one_day_only) this.rows.splice(1);
    },

    addRow() {
      // import selects from prev row if exists
      const prevRow = this.rows[this.rows.length - 1];
      const selectedMeasurement = this.measurementsList.length === 1 ? this.measurementsList[0] : undefined;
      this.rows.push({
        channel: prevRow?.channel || this.channels?.find((c) => c.id === +this.channelId) || '',
        measurement: prevRow?.measurement || selectedMeasurement || '',
        file: null,
        time_delta: prevRow?.time_delta || null,
        clear_day: false,
        dayFrom: prevRow?.dayFrom || this.selectedDay || '',
        dayTo: prevRow?.dayTo || this.selectedDay || '',
        isExistImports: null,
        status: {
          result: '',
          data: [],
        },
        existImports: [],
      });
      //check for import if channel+day
      this.checkImportsInDay(this.rows.length - 1);
    },

    deleteRow(index) {
      this.rows.splice(index, 1);
    },

    hideModalImportFactRatings() {
      this.$bvModal.hide('import-fact-ratings-modal');
    },

    selectedDayFrom(index) {
      // smart autofill for DayTo
      if (!this.rows[index].dayFrom) return;
      if (!this.rows[index].dayTo || new Date(this.rows[index].dayTo).getTime() < new Date(this.rows[index].dayFrom).getTime())
        this.rows[index].dayTo = this.rows[index].dayFrom;
      this.checkImportsInDay(index);
    },

    async checkImportsInDay(index = 0) {
      if (this.rows[index].channel && this.rows[index].dayFrom) {
        const formData = {
          channel_id: this.rows[index].channel.id,
          dates: getDatesInRange(this.rows[index].dayFrom, this.rows[index].dayTo),
        };
        await this.$store.dispatch('GET_CHECK_IMPORTS_FACT', {
          formData,
          handler: (res) => {
            this.rows[index].existImports = res.data ? Object.keys(res.data) : [];
            this.rows[index].isExistImports = res.data ? true : false;
            const data = res.data ? Object.values(res.data) : null;
            // restore delta params if exists
            const firstDelta = data && data[0] && data[0].time_delta ? data[0].time_delta : null;
            this.rows[index].time_delta = firstDelta;

            if (this.rows[index].isExistImports === false) this.rows[index].clear_day = true;
            else this.rows[index].clear_day = false;
          },
        });
      }
    },

    async importFactRatings() {
      this.processing = true;
      for (let row of this.rows) {
        if (row.status.result === 'success') continue; // don't upload already imported files
        row.status.result = 'processing';
        const delta = row.time_delta ? parseInt(row.time_delta) : null;
        const channel = row.channel;
        const dayFrom = row.dayFrom;
        const dayTo = row.dayTo;

        let formData = new FormData();
        formData.append('channel_id', channel?.id);
        formData.append('measurement_company_id', row.measurement?.id);
        if (delta) formData.append('time_delta', delta);
        formData.append('file', row.file);
        formData.append('clear_day', row.clear_day ? 1 : 0);
        const days = getDatesInRange(row.dayFrom, row.dayTo);
        days.forEach((d) => formData.append('broadcast_days[]', d));
        await this.$store.dispatch('POST_FACT_GRP_UPLOAD', {
          formData,
          handler: (res) => {
            row.status.result = 'success';
            row.status.data = res.data || [];
            this.$notify({
              type: 'success',
              title: `${this.$i18n.t('alert.importQueued')}`,
              text: `${channel?.name} - (${dayFrom}-${dayTo})`,
            });
          },
          handlerError: (errors) => {
            row.status.result = 'error';
            row.status.data = [];
            errorsHandler(errors, this.$notify);
          },
        });
      }
      this.processing = false;
      if (this.one_day_only && this.rows[0] && this.rows[0].status.result === 'success' && this.rows[0].channel && this.rows[0].dayFrom) {
        // do only for 1-file mode
        //append list of succeeded imports
        const allSuccessfulCouples = this.getAllSuccessfulCouples();
        if (this.$route.name === 'matchingFactImport') {
          // update only if user on matching fact page
          this.$emit('updatePage', true, { date: this.rows[0].dayFrom, channel: this.rows[0].channel, queue: allSuccessfulCouples });
          this.hideModalImportFactRatings();
        } else if (this.open_matching_page) {
          this.hideModalImportFactRatings();
          this.$router.push({
            name: 'matchingFactImport',
            query: { date: this.rows[0].dayFrom, channel_id: this.rows[0].channel.id, queue: allSuccessfulCouples },
          });
        }
      }
    },

    getAllSuccessfulCouples() {
      const rows = this.rows.filter((r) => r.status.result === 'success');
      const couplesData = [];
      rows.forEach((r) => couplesData.push(...r.status.data));
      return JSON.stringify(couplesData);
    },

    checkIfValidThenEnter() {
      if (!this.$v.$invalid) this.importFactRatings();
    },

    openMatchingFactPage(row, ex, isAll) {
      //append list of succeeded imports
      const allSuccessfulCouples = isAll ? this.getAllSuccessfulCouples() : undefined;
      this.hideModalImportFactRatings();
      if (this.$route.name === 'matchingFactImport') {
        this.$emit('updatePage', true, { date: ex || row.dayFrom, channel: row.channel, queue: allSuccessfulCouples });
      } else {
        this.$router.push({ name: 'matchingFactImport', query: { date: ex || row.dayFrom, channel_id: row.channel.id, queue: allSuccessfulCouples } });
      }
    },
  },
};
</script>

<style lang="sass">
.modal-w-1600px
  width: calc(100% - 16px)
  max-width: 1800px

@media (max-width: 1600px)
  .modal-w-1600px .datepickers-wrapper
    flex-direction: column
@media (min-width: 1600px)
  .datepickers-wrapper-header
    div
      width: 150px
    span
      display: none
</style>
