/**Return dates in range
 *
 * @param {String} start
 * @param {String} end
 * @returns
 */
export default function getDatesInRange(start, end) {
  if (!start) return [];
  if (!end) return [start];
  const arr = [];
  const dt = new Date(start);
  for (arr, dt; dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt).toISOString().slice(0, 10));
  }
  return arr;
}
